import styled from "styled-components";

export const FormField = styled.input`
  display: block;
  border: none;
  text-transform: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;
